export function scrollIntoView() {
  const scrollIntoViewAll = document.querySelectorAll("[data-scroll-into-view]");
  scrollIntoViewAll.forEach((scrollIntoView) => {
    const parameters = JSON.parse(scrollIntoView.dataset.scrollIntoView || "{}");

    const containerEl = scrollIntoView.querySelector(parameters.containerEl);
    if (!containerEl) return;
    const containerElRect = containerEl.getBoundingClientRect();

    const activeEl = scrollIntoView.querySelector(parameters.activeEl);
    if (!activeEl) return;
    const activeElRect = activeEl.getBoundingClientRect();

    const scrollLeft = Math.round(activeElRect.left - containerElRect.left - containerElRect.width / 2 + activeElRect.width / 2);

    if (scrollLeft > 0) {
      containerEl.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  });
}
